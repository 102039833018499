// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-js": () => import("./../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sitemap-js": () => import("./../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-case-study-js": () => import("./../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */)
}

